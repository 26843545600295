<template>
  <pcis-steps :form-type="formType" ref="steps" :enableButtons="true" @trigger="triggerHandlePayment">
    <el-card v-loading="loading" element-loading-background="#ffffff" :element-loading-text="$t('common.loading.default')">
      <div :class="isLoading">
        <p>{{$t("personal.payment.text")}}</p>
        <el-divider />
        <!-- <el-row>
          <el-col :span="24">
            <h4 class="nomargin">{{ t("onlinePayment.text") }}</h4>
          </el-col>
        </el-row> -->
        <el-row :gutter="40" v-if="!loading" class="online-payment">
          <el-col
            :xs="12"
            :sm="8"
            :md="6"
            :lg="4"
            v-for="item in methods"
            :key="item.index"
          >
            <el-card class="payment-options" @click="selectMethod($event, item.method)">
              <img style="max-width:80%; max-height:50px; object-fit: contain;" :src="`data:image;base64,${item.icon}`" :alt="item.name" />
            </el-card>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :xs="24" :sm="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
            <div class="payment-option">
              <div>
                <el-card class="payment-options" @click="onNextClick(0)">
                  <img style="width: 100%;" src="@/assets/pp-logo-200px.png" />
                </el-card>
              </div>
              <div>
                <el-card class="payment-options" @click="onNextClick(1)">
                  <img style="width: 180px;" src="@/assets/Octopus.jpg" />
                </el-card>
              </div>
            </div>
          </el-col>
        </el-row> -->
        <el-divider />
      </div>
    </el-card>
    <template #buttons>
      <div :class="['steps-button', isLoading]">
        <el-button
          type="primary"
          round
          @click="onNextClick"
        >
          {{ $t("common.button.next") }}
        </el-button>
      </div>
      <!-- <button @click="initFrame">Test</button> -->
    </template>
    <!-- <template #buttons>
      <div class="steps-button">
        <el-button type round @click="onPrevClick">{{ $t("common.button.back") }}</el-button>
      </div>
    </template> -->
  </pcis-steps>
</template>

<style scoped>
/* .el-card {
  margin: 20px 0;
  text-align: center;
  font-size: 20px;
}
.el-icon {
  font-size: 10em;
  color: #67c23a;
}

.steps-button {
  margin: 20px 0px;
  text-align: center;
}
.el-button {
  width: 100%;
  max-width: 300px;
}
.el-card .payment-options {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.payment-option,
.payment-option > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 1
} */
.cuc-payment-span{
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.cuc-payment-text {
  display: flex;
  align-items: center;
}
/* .cuc-payment .el-col {
  margin-top: 10px;
} */
/* .el-card {
  margin: 20px 0;
  text-align: left;
  font-size: 16px;
} */

.payment-options.selected {
  border: 4px solid rgba(64,158,255, 0.8);
  width: calc(100% - 8px) !important;
  height: calc(80px - 8px) !important;
}
/* .payment-options-cuc.selected {
  border: 3px solid rgba(64,158,255, 0.8);
  width: calc(100% - 2px) !important;
  height: calc(100% - 2px) !important;
} */
.payment-options.selected:after {
  content: "\2714 \fe0e";
  color: var(--el-color-white);
  position: absolute;
  top: -5px;
  right: 0px;
  border-radius: 0px 0px 0px 5px;
  padding: 5px 0px 0px 4px;
  background-color: rgba(64,158,255, 0.8);
  height: 20px;
}
/* .payment-options-cuc.selected:after {
  content: "\2714 \fe0e";
  color: var(--el-color-white);
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 0px 0px 0px 5px;
  padding: 1px 3px 3px 6px;
  background-color: rgba(64,158,255, 0.8);
} */

.el-card .payment-options >>> div.el-card__body,
.el-card .payment-options.selected >>> div.el-card__body {
  padding: 0px !important;
  text-align:center;
  width: 100%;
}
.el-icon {
  font-size: 10em;
  color: #67c23a;
}

.steps-button {
  margin: 20px 0px;
  text-align: center;
}
.el-button {
  width: 100%;
  max-width: 300px;
}
.online-payment {
  width: calc(100% + 30px);
  margin-top: 10px;
}
.online-payment > .el-col {
  min-height: 100px;
}
.el-card .payment-options {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: none;
  position: relative;
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.payment-option,
.payment-option > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.content {
  margin-bottom: 10px;
}
.payment-collapse :deep(.el-collapse-item__content) {
  padding-bottom: 10px;
}
</style>

<script>
import BaseStep from "@/views/personal/steps/BaseStep.vue";

// import application from "@/http/apis/personal/application";
import payment from "@/http/apis/personal/payment";
import maint from "@/http/apis/personal/maint";
// import { handlePersonalException, handlePayment, authenticateToken, parseJWT } from "../../../libs/common";
import { handlePersonalException, handlePayment, checkBackForwardAction, checkIfBoCPayment, handleBoCPayment} from "../../../libs/common";
import { WarningAlert, WarningAlertWithCallback } from "@/plugins/message"
import store from "../../../store";
import * as loading from "@/plugins/loading";
import navigation from "@/libs/navigation";

export default {
  extends: BaseStep,
  name: "PersonalPayment",
  data() {
    return {
      loading: true,
      methods: [],
      selected: null
    }
  },
  methods: {
    onPrevClick() {
      this.$refs.steps.goPrev();
    },
    async onNextClick() {
      // const loadingInstance = ElLoading.service({
      //   text: this.$i18n.t("common.loading.process")
      // });

      // let jwt = this.$store.getters.personalJWT;
      // await authenticateToken(parseJWT(jwt).token, false);
      
      // let personal = this.$store.state.personal;
      // personal.form.paymentMethod = paymentMethod;
      // personal.jwt = jwt;
      // this.$store.commit("updateForm", personal.form);

      // let personal = this.$store.state.personal;
      // if(personal.form.id) {
      //   this.createPayment(personal.jwt, paymentMethod, personal.form.id, loadingInstance);
      //   return;
      // }
      // application.postSubmitForm(personal.jwt, personal.form).then((res)=> {
      //   let formId = res.data.id;
      //   this.$store.commit("updatePersonalAppNo", formId);
      //   this.createPayment(personal.jwt, paymentMethod, formId, loadingInstance);
      //   // this.$refs.steps.goNext();
      // }).catch((err) => {
      //   handlePersonalException(err)
      //   loadingInstance.close();
      // })
      // .finally(() => {
      //   loadingInstance.close();
      // })

      if (this.selected == null) {
        WarningAlert(this.$i18n.t("exceptions.noPayment"));
        return;
      }
      this.createPayment(this.selected);
    },
    async createPayment(method) {
      let loadingInstance = loading.create("process");
      let personal = this.$store.state.personal;
      let checkRes = await payment.checkPayment(personal.jwt, personal.form.id);
      if (checkRes.status !== 200) {
        handlePersonalException(checkRes);
        loadingInstance.close();
        return;
      }
      this.$store.commit("updatePersonalPaymentMethod", {
        method: checkRes.data.method,
        refNum: checkRes.data.id
      });
      if (checkRes.data.status == 4) {
        navigation.goComplete();
        loadingInstance.close();
        return;
      }
      let result = checkIfBoCPayment(checkRes.data);
      if (result.isBOC && checkRes.data.status == 1) {
        handleBoCPayment(result, checkRes.data.redirectUrl);
        loadingInstance.close();
        return;
      }
      // if (isCredit) return;
      payment.postCreatePayment(personal.jwt, method, personal.form.id)
        .then((res1) => {
          if (res1.status == 200 && Object.prototype.hasOwnProperty.call(res1.data, "redirectUrl")) {
            this.$store.commit("updatePersonalLocale", this.$i18n.locale);
            this.$store.commit("updatePersonalPaymentMethod", {
              method: res1.data.method,
              refNum: res1.data.id
            });
            if (res1.data.redirectUrl) {
              result = checkIfBoCPayment(res1.data);
              if(result.isQR) {
                this.$router.push({
                  name: "StepPaymentLanding", 
                  params: {
                    locale: this.$i18n.locale,
                    url: res1.data.redirectUrl
                  }
                });
                loadingInstance.close();
              } else {
                checkBackForwardAction();
                window.location.href = res1.data.redirectUrl;
              }
            } else {
              WarningAlert(this.$i18n.t("exceptions.default")) 
            }
          } else {
            WarningAlert(this.$i18n.t("exceptions.default"))
          }
        }).catch((err1) => {
          handlePersonalException(err1);
          loadingInstance.close();
        });
    },
    selectMethod(event, method) {
      this.selected = method;
      let selected = document.querySelector(".selected");
      if (selected) selected.classList.remove("selected");
      event.currentTarget.classList.add("selected");
    },
    clearSelected() {
      this.selected = null;
      let selected = document.querySelector(".selected");
      if (selected) selected.classList.remove("selected");
    },
    async initPaymentPage() {
      try {
        this.loading = true;
        let personal = this.$store.state.personal;
        let result = await handlePayment(personal.form.id);
        if (result.status == 4) return;
        let res = await maint.getPaymentMethods(personal.jwt, this.$i18n.locale, personal.isMobile);
        if (res) {
          console.log(res.data)
          this.methods = res.data;
        }
      } catch (err) {
        console.log("failed", err);
        this.showWarningAndRefresh(this.$i18n.t("exceptions.failGetData"))
      } finally {
        this.loading = false;
      }
    },
    async triggerHandlePayment() {
      let personal = store.state.personal;
      if (personal.form.id) {
        this.initPaymentPage();
      } else {
        this.onPrevClick();
      }
      
    },
    showWarningAndRefresh(msg, title = "") {
      WarningAlertWithCallback(msg, title, {
        closable: false,
        callback: ()=> {window.location.reload()}
      })
    }
  },
  computed: {
    isSelected() {
      return this.selected !== null ? !!document.querySelector(".payment-options.selected") : false;
    }
  }
  // async beforeRouteEnter(to, from, next) {
  //   let personal = store.state.personal;
  //   if(personal.form.id) {
  //     await handlePayment(personal.form.id);
  //     next();
  //   } else {
  //     next();
  //   }
  // }
};
</script>
