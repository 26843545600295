import http from "@/http"

let modulePath = "Maint/payment";

const get = function(path, locale, config) {
  return http.get(`${locale}/${modulePath}/${path}`, config);
}

export default  {
  getPaymentMethods(jwt, locale, isMobile) {
    return get("methods", locale, {
      headers: {
        Authorization: "Bearer " + jwt
      },
      params: {
        device: isMobile ? 2 : 1
      }
    })
  }
}